<template>
    <div>analysis view</div>
</template>

<script>

export default {
    props: [],
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}

</script>